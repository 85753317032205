import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

function CategorySection() {

  const categoryQuery = useStaticQuery(
    graphql`
      query WordPressCategorys{
        allWpCategory {
          nodes {
            name
            uri
          }
        }
      }
    `
  )

  let categories = categoryQuery.allWpCategory.nodes
  for (let i = 0; i < categories.length; i++) {
    if (categories[i].name === "Highlight") {
      categories.splice(i, 1)
    }
    if (categories[i].name === "Mais lidos") {
      categories.splice(i, 1)
    }
  }

  return (
    <div className="hidden xl:flex flex-col">
      <h5 className="font-semibold text-center md:text-left mb-5">Categorias</h5>
      <ul className="grid grid-cols-2">

      {categories.map((category, i) => 
        <Link key={i} to={category.uri} className="hover:text-greenQS transition">
          {category.name}
        </Link>
      )}

      </ul>
    </div>
  )
}

export default CategorySection